<template>
  <v-form @submit.prevent="handleConsultarVerificarDocumento()">
    <v-row>
      <v-col>
        <v-card class="pa-5 mt-3">
          <div
              class="d-flex flex-column flex-sm-row align-stretch align-sm-baseline"
          >
            <v-text-field
                v-model.number="codigoDocumento"
                label="Ingrese el código del documento"
                type="number"
                :autofocus="!$vuetify.breakpoint.mobile"
                ref="nDocumentoInput"
                :error-messages="errorCodigoDocumento"
                @blur="$v.codigoDocumento.$touch"
                @input="delayTouch($v.codigoDocumento)"
            ></v-text-field>
            <v-btn
                class="ml-0 ml-sm-3 mt-3 mt-sm-0"
                color="primary"
                :loading="cargandoInfo"
                type="submit"
                small
            >
              <v-icon size="16" left>mdi-check</v-icon>
              Verificar documento
            </v-btn
            >
          </div>
          <v-row>
            <v-col cols="12">
              <v-card outlined>
                <v-card-title>
                  <v-icon left>mdi-information</v-icon>
                  Verificación de documentos
                </v-card-title>
                <v-card-text>
                  Ingrese el código de verificación de un documento firmado electrónicamente (impreso en el margen inferior izquierdo de éste) y luego presione el botón "verificar documento".
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import documentoEntity from "@/model/fe-digital/documento.entity";
import {required} from 'vuelidate/lib/validators'

const touchMap = new WeakMap();

export default {
  name: "ConsultasEnLineaViewVerificarDocumento",
  data() {
    return {
      codigoDocumento: null,
      cargandoInfo: false,
      error: null,
    }
  },
  validations: {
    codigoDocumento: {required},
  },
  mounted: function () {
    this.handleVerificarDocumento();
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.handleVerificarDocumento();
  },
  methods: {
    delayTouch($v) {
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 1000))
    },
    handleVerificarDocumento() {
      if(!this.$vuetify.breakpoint.mobile)
      {
        this.$refs.nDocumentoInput.focus();
      }

      //si se tiene el número de documento por url
      if (this.$route.params.ndocumento) {
        this.codigoDocumento = this.$route.params.ndocumento;
        this.verificarDocumento(this.codigoDocumento);
      }
    },
    handleConsultarVerificarDocumento() {


      if (this.$route.path !== `/consultas/verificar-documento/${this.codigoDocumento}`) {
        this.$router.replace({
          name: "ConsultasVerificarDocumento",
          params: {ndocumento: this.codigoDocumento},
        });
      }else{
        this.handleVerificarDocumento();
      }


    },
    verificarDocumento(codigoDocumento) {

      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.cargandoInfo = true;
      documentoEntity.verificarDocumento(codigoDocumento)
          .then((response) => {
            window.open(response, "_blank");
          })
          .catch((error) => {
            this.error = error.mensaje ? error.mensaje : error;
            console.error(error);
          })
          .finally(() => {
            this.cargandoInfo = false;
          });
    }
  },
  computed: {
    errorCodigoDocumento() {
      const errors = [];
      if (!this.$v.codigoDocumento.$dirty) return errors;
      !this.$v.codigoDocumento.required && errors.push("Por favor, ingrese el código de su documento");
      return errors;
    },
  }
}
</script>