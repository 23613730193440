<template>
  <v-container class="pa-0">

    <div class="d-flex flex-column flex-md-row mt-3">
      <div>
        <indices-nav @itemSeleccionado="cambiarPanel($event)" :mostrar-en-lista="mostrarEnLista"></indices-nav>
      </div>
      <div class="flex-fill ml-md-3 ">
        <indices-view-tipo
            :itemNav="itemNav"
        ></indices-view-tipo>
      </div>
    </div>


  </v-container>
</template>

<script>
import IndicesNav from "@/views/index/indices/IndicesNav";
import IndicesViewTipo from "@/views/index/indices/IndicesTipo";

export default {
  name: "IndicesView",
  components: {IndicesViewTipo, IndicesNav,},
  data() {
    return {
      itemNav: null,
      lista_registros: [],
      url_categoria: null,
      cargandoCategorias: false,
      mostrarEnLista: "comercio",
    }
  },
  methods: {
    cambiarPanel(panel) {
      this.itemNav = panel;
    }
  },
  computed:{
    getIdTipoRegistro(){
      if(this.itemNav != null){
        return this.itemNav.id_tipo_registro;
      }else{
        return 0;
      }

    }
  }

}
</script>

<style scoped>

</style>
