<template>
<div>
  <v-list class="pt-0" dense>
    <template
        v-for="(documento,index) in lista_boletas"
    >
      <v-list-item
          :key="documento.codigo_barras"
          :href="getLink(documento.codigo_barras)"
          target="_blank"
      >
        <span class="blue--text">
          <v-icon
              size="16"
              color="blue"
              left
          >
            mdi-open-in-new
          </v-icon>
          Código boleta: {{ documento.codigo_barras }}
        </span>
      </v-list-item>
      <v-divider
          v-if="index < lista_boletas.length - 1"
          :key="index"
      />
    </template>
  </v-list>
</div>

</template>

<script>
export default {
  name: "ListaBoletasDescargables",
  props: {
    lista_boletas: {
      default: null
    }
  },
  methods: {
    getLink(codigo_barras){
      return  process.env.VUE_APP_API_URL + "/boletapdf?id=" + codigo_barras
    }
  }
}
</script>

<style scoped>

</style>