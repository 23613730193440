<template>
  <v-form>
    <v-fade-transition hide-on-leave>
      <v-row key="seleccion 0">
        <!-- seleccion no sabe sus datos, buscar por nombre -->
        <v-col cols="12" class="pt-0 mt-0 pb-0">
          <p class="font-weight-regular mb-3">Ingrese el nombre y apellido del propietario del inmueble junto al año en el cual fue inscrita su Escritura.</p>
        </v-col>
        <v-col class="mt-0 pt-0 pb-0 mb-0">
          <panel-buscar-por-titular-escritura-publicas
              @seleccionarItem="mostrarModalConfirmarDatosAdicionales($event)"
              @ejecutarVolver="seleccionarOpcionPrincipalSabeLosDatosDeEscritura(-1);$emit('MostrarElemento', false);"
              :titular-escritura="true"
              @mostrarModalProblemaEscritura="mostrarModalProblemaEscritura($event)"
          />
        </v-col>
      </v-row>
    </v-fade-transition>

    <modal-tabla-escrituras-busqueda
        :datosConocidos="NoSeRepertorio ?  'Notaria: '+notario_select.notario + '/// Fecha: ' + fecha : 'Notaria: '+notario_select.notario + '/// Repertorio: ' + repertorio"
        :radioSabeRepertorio="NoSeRepertorio"
        :escriturasEncontradas="escriturasEncontradas"
        :ver-dialog="verModalEscrituras"
        @cargarSeleccionado="cargarSeleccionado($event)"
        @ocultarDialog="verModalEscrituras = $event; escriturasEncontradas=[]"
    />
    <modal-mensaje
        :ver-dialog="mostrarDialogMensaje"
        @ocultarDialog="mostrarDialogMensaje = $event"
    />
    <modal-mensaje-simple-reutilizable
        :mensajeTitulo="'Escritura no disponible'"
        :mensaje="mensajeSimpleModalProblemaEscritura"
        :ver-dialog="verSimpleModalProblemaEscritura"
        @ocultarDialog="verSimpleModalProblemaEscritura = $event"
    >
    </modal-mensaje-simple-reutilizable>
    <modal-confirmar-datos-adicionales-escritura-publica
        :item-datos-adicionales="itemDatosAdicionalesParaModalConfirmar"
        :ver-dialog="verModalConfirmarDatosAdicionalesEscrituraPublica"
        @confirmarItem="reenviarDataFormularioFromBusquedaTitularesTabla($event)"
        @e-ocultar-dialog="verModalConfirmarDatosAdicionalesEscrituraPublica = false"
    />
  </v-form>

</template>

<script>
import {ID_TIPO_FORMULARIO} from "@/model/formularios/formulario.entity";
import {ID_AREA_AGRUPACION} from "@/model/registros/registros.entity";
import notariasSiiEntity from "@/model/notarias-sii/notarias-sii.entity";
import {escrituraEntity} from "@/model/escritura/escritura.entity";
import moment from 'moment';
import ModalMensaje from "@/views/index/tramites-en-linea/ModalMensaje.vue";
import tituloEntity from "@/model/titulo/titulo.entity";
import ModalTablaEscriturasBusqueda from "@/components/SolicitudDocumentos/modals/ModalTablaEscriturasBusqueda.vue";
import ModalConfirmarDatosAdicionalesEscrituraPublica from "@/components/Tramites/ModalConfirmarDatosAdicionalesEscrituraPublica.vue";
import ModalMensajeSimpleReutilizable from "@/components/Dialogs/ModalMensajeSimpleReutilizable.vue";
import PanelBuscarPorTitularEscrituraPublicas from "@/components/EscriturasPublicas/PanelBuscarPorTitularEscrituraPublicas.vue";

export default {
  name: "FormularioEscrituraPublica",
  components: {
    ModalMensajeSimpleReutilizable,
    ModalConfirmarDatosAdicionalesEscrituraPublica,
    PanelBuscarPorTitularEscrituraPublicas,
    ModalTablaEscriturasBusqueda,
    ModalMensaje,
  },

  props: {
    camposHabilitados: {
      type: Boolean,
      default: false,
    },
  },


  data() {
    return {
      verModalConfirmarDatosAdicionalesEscrituraPublica: false,
      itemDatosAdicionalesParaModalConfirmar: {},
      verSimpleModalProblemaEscritura: false,
      mensajeSimpleModalProblemaEscritura: "",
      /*-1 = no seleccionado
      * 0 = no se los sabe, búsqueda por nombre
      * 1 = si se lo sabe, formulario normal
      * */
      seleccionPrincipalNoSeLosDatosDeEscritura: -1,
      rulesRepertorio: [
        value => (value && /^\d+-\d+$/.test(value)) || 'El formato debe ser numero-año',
      ],
      notaria_manual: "",
      repertorio: "",
      fecha: "",
      contratantes: "",
      NoSeRepertorio: false,
      NoSeFecha: false,
      notarios: [
        {nombre: 'Oscar Fernandez Mora', id: 1},
        {nombre: 'Elena Leyton Carvajal', id: 2},
        {nombre: 'Jaime Morande Miranda', id: 3},
        {nombre: 'Ruben Reinoso Herrera', id: 4},
        {nombre: 'Pedro Villarino', id: 5},
        {nombre: 'Mariano Torrealba', id: 6},
        {nombre: 'Alejandro Viada Ovalle', id: 7},
        {nombre: 'Luz Alvarez Madariaga', id: 8},
        {nombre: 'Raul Parga Muñoz', id: 9},
        {nombre: 'Carlos Medina Fernandez', id: 10},
      ],
      notario_select: {notario: 'Oscar Fernandez Mora'},
      notario_no_encontrado: false,
      nowDate: new Date().toISOString().slice(0, 10),
      //fecha_minima: 10800000,
      radioSabeRepertorio: false,
      radioSabeFecha: false,
      repertorio_anho: null,
      notariasSii: null,
      cargandoNotariasSii: false.valueOf(),
      escrituraIsBuscada: false,
      buscandoEscritura: false,
      mostrarDialogMensaje: false,
      verModalEscrituras: false,
      escriturasEncontradas: []
    }
  },
  mounted() {
    this.traerNotarias()
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    customFilter(item, queryText, itemText) {
      const textOne = item.nombre.toLowerCase()
      const textTwo = item.id.toLowerCase()
      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1 ||
          textTwo.indexOf(searchText) > -1
    },
    async cargarSeleccionado(nuevaCarga) {
      console.log("nuevaCarga: ", nuevaCarga);
      this.repertorio = nuevaCarga.repertorio
      this.fecha = new Date(nuevaCarga.fecha).toISOString().slice(0, 10);
      console.log(this.fecha)
      this.NoSeFecha = false
      this.NoSeRepertorio = false
      await this.buscarEscritura()
    },
    buscarContratantes(numero, anho) {
      tituloEntity.getContratantes(numero, anho).then(res => {
        this.contratantes = tituloEntity.concatenarContratantes(res);
      })
    },
    buscarEscritura() {
      this.buscandoEscritura = true
      let splitRepertorio = ''
      let numeroRepertorio = ''

      if (this.NoSeRepertorio) {

        this.opcionNoConozcoRepertorio()

      } else if (this.NoSeFecha) {

        splitRepertorio = this.repertorio.split('-')
        numeroRepertorio = splitRepertorio[0]
        this.opcionNoConozcoFecha(numeroRepertorio)

      } else {
        splitRepertorio = this.repertorio.split('-')
        numeroRepertorio = splitRepertorio[0]
        this.repertorio_anho = null

        this.escrituraIsBuscada = false
        this.contratantes = '';
        let dateLong = moment(this.fecha)
        console.log(dateLong.valueOf())
        escrituraEntity.obtenerEscritura(this.notario_select.id_notaria_sii, numeroRepertorio, dateLong.valueOf()).then(res => {
          console.log("res xd 1", res)

          if (res.data.status && res.data.code == 'OBTENER_ESCRITURA:NOT_FOUNDALL') {
            this.mostrarDialogMensaje = true;
            console.log("res no encontrado", res)

          }
          if (res.data.status && res.data.code == 'OBTENER_ESCRITURA:READOK') {
            console.log("res xd 2", res)
            this.escrituraIsBuscada = true
            this.buscandoEscritura = false
            this.repertorio_anho = res.data.obj.anho_FK
            this.buscarContratantes(res.data.obj.numero_FK, res.data.obj.anho_FK)
            this.$emit("e-cambiar-valor-certificado", 20000)
          } else if (res.data.status) {
            this.$emit("e-cambiar-valor-certificado", 0)
            this.escrituraIsBuscada = true
            this.buscandoEscritura = false
          }

        })
      }
    },

    opcionNoConozcoRepertorio() {
      let dateLong = moment(this.fecha)
      console.log('info a enviar ', dateLong.valueOf(), this.notario_select.id_notaria_sii)
      escrituraEntity.obtenerEscrituras(this.notario_select.id_notaria_sii, undefined, dateLong.valueOf()).then(res => {
        if (res.data.status) {

          this.escriturasEncontradas = res.data.obj

          this.escriturasEncontradas.map(escritura => {
            let obj = {
              compradores: escritura.contratantes[0],
              vendedores: escritura.contratantes[1],
              mostrarTooltip: false,
            }
            escritura.contratantes = obj
            console.log(obj)
          })
          this.buscandoEscritura = false
        }
        this.verModalEscrituras = true
      })
    },

    opcionNoConozcoFecha(numero) {
      console.log('info a enviar ', numero, this.notario_select.id_notaria_sii)
      escrituraEntity.obtenerEscrituras(this.notario_select.id_notaria_sii, numero, undefined).then(res => {
        if (res.data.status) {

          this.escriturasEncontradas = res.data.obj

          this.escriturasEncontradas.map(escritura => {
            let obj = {
              compradores: escritura.contratantes[0],
              vendedores: escritura.contratantes[1],
              mostrarTooltip: false,
            }
            escritura.contratantes = obj
            console.log(obj)
          })
        }
        this.buscandoEscritura = false
        console.log(res)
      })
      this.verModalEscrituras = true
    },
    traerNotarias() {
      this.cargandoNotariasSii = true
      notariasSiiEntity.obtenerNotariasSii().then(res => {
        console.log("res notaria sii ", res)
        this.cargandoNotariasSii = false
        this.notariasSii = res.data.obj
        this.notario_select = this.notariasSii[0]
      })
    },

    seleccionarOpcionPrincipalSabeLosDatosDeEscritura(opcion) {
      this.seleccionPrincipalNoSeLosDatosDeEscritura = opcion;
      if (opcion == -1) {
        this.$emit('MostrarElemento', false);
        //al haber un OR en la condición de mostrar elementos, debo emitir el siguiente evento también
        this.$emit('notarioNoEstaEnLista', false);
        //falta resetear el formulario cuando si sabe los datos de la notaría
      }
    },

    mostrarModalProblemaEscritura(data) {
      this.verSimpleModalProblemaEscritura = true;
      this.mensajeSimpleModalProblemaEscritura = data.mensaje;
    },
    seleccionarItemEscritura(item) {
      console.log("seleccionar item escritura: ", item);
    },
    mostrarModalConfirmarDatosAdicionales(item) {
      this.itemDatosAdicionalesParaModalConfirmar = item;
      this.verModalConfirmarDatosAdicionalesEscrituraPublica = true;
      /** cuando se confirme este modal, debería llamar a reenviarDataFormularioFromBusquedaTitularesTabla(item) */
    },
    /**
     * Crea el objeto "formulario" que se envía para crear el documento y sus datos adicionales
     * @param item objeto obtenido desde la búsqueda por nombre, con la información a añadir en los datos adicionales
     * */
    reenviarDataFormularioFromBusquedaTitularesTabla(item) {
      console.log(typeof item.contratantes)
      let formulario = {
        id_tipo_formulario: ID_TIPO_FORMULARIO.ESCRITURAS_PUBLICAS,
        datos_adicionales: [
          {
            nombre: "notaria",
            valor: item.notario,
            id_tipo_formulario: ID_TIPO_FORMULARIO.ESCRITURAS_PUBLICAS,
            id_area_agrupacion: ID_AREA_AGRUPACION.ESCRITURAS_PUBLICAS
          },
          {
            nombre: "repertorio",
            valor: escrituraEntity.repertorioByFechaEscritura(item.numero_repertorio, item.fecha_escritura),
            id_tipo_formulario: ID_TIPO_FORMULARIO.ESCRITURAS_PUBLICAS,
            id_area_agrupacion: ID_AREA_AGRUPACION.ESCRITURAS_PUBLICAS
          },
          {
            nombre: "fecha",
            valor: item.fecha_escritura,
            id_tipo_formulario: ID_TIPO_FORMULARIO.ESCRITURAS_PUBLICAS,
            id_area_agrupacion: ID_AREA_AGRUPACION.ESCRITURAS_PUBLICAS
          },
          {
            nombre: "contratantes",
            valor: typeof item.contratantes == 'string' ? item.contratantes : escrituraEntity.concatenarContratantes(item.contratantes),
            id_tipo_formulario: ID_TIPO_FORMULARIO.ESCRITURAS_PUBLICAS,
            id_area_agrupacion: ID_AREA_AGRUPACION.ESCRITURAS_PUBLICAS
          }
        ]
      };
      this.$emit("setFormulario", formulario);
      this.$emit('MostrarElemento', true);

      // aqui se cambia el precio del certificado, si es 0 muestra por cotizar.
      // si llega true, se settea (por documentar).
      this.$emit("e-cambiar-valor-certificado", item.enviarItem !== true ? 20000 : 0);
      console.log("evento reenviardataformulario: ", formulario, "item", item);
    }

  },
  computed: {

    getDataFormulario() {
      let fecha_split = this.fecha.split("-");
      console.log("dsakdskajdskadsa: " + this.repertorio + "-" + this.repertorio_anho)
      if (this.validarFormularioValido) {


        let formulario = {
          id_tipo_formulario: ID_TIPO_FORMULARIO.ESCRITURAS_PUBLICAS,
          datos_adicionales: [
            {
              nombre: "notaria",
              valor: this.notario_no_encontrado ? this.notaria_manual : this.notario_select.notario,
              id_tipo_formulario: ID_TIPO_FORMULARIO.ESCRITURAS_PUBLICAS,
              id_area_agrupacion: ID_AREA_AGRUPACION.ESCRITURAS_PUBLICAS
            },


            {
              nombre: "repertorio",
              valor: this.radioSabeRepertorio == "1" ? (this.repertorio + "-" + this.repertorio_anho) : "-",
              id_tipo_formulario: ID_TIPO_FORMULARIO.ESCRITURAS_PUBLICAS,
              id_area_agrupacion: ID_AREA_AGRUPACION.ESCRITURAS_PUBLICAS
            },
            {
              nombre: "fecha",
              valor: this.radioSabeRepertorio == "2" && this.radioSabeFecha == '1' ? new Date(parseInt(fecha_split[0]), parseInt(fecha_split[1]) - 1, parseInt(fecha_split[2])).getTime() : null,
              id_tipo_formulario: ID_TIPO_FORMULARIO.ESCRITURAS_PUBLICAS,
              id_area_agrupacion: ID_AREA_AGRUPACION.ESCRITURAS_PUBLICAS
            },
            {
              nombre: "contratantes",
              valor: this.contratantes,
              id_tipo_formulario: ID_TIPO_FORMULARIO.ESCRITURAS_PUBLICAS,
              id_area_agrupacion: ID_AREA_AGRUPACION.ESCRITURAS_PUBLICAS
            }
          ]
        }

        if (!this.notario_no_encontrado) {
          formulario = {
            id_tipo_formulario: ID_TIPO_FORMULARIO.ESCRITURAS_PUBLICAS,
            datos_adicionales: [
              {
                nombre: "notaria",
                valor: this.notario_select.notario,
                id_tipo_formulario: ID_TIPO_FORMULARIO.ESCRITURAS_PUBLICAS,
                id_area_agrupacion: ID_AREA_AGRUPACION.ESCRITURAS_PUBLICAS
              },
              {
                nombre: "repertorio",
                valor: this.repertorio_anho != null ? (this.repertorio + "-" + this.repertorio_anho) : "-",
                id_tipo_formulario: ID_TIPO_FORMULARIO.ESCRITURAS_PUBLICAS,
                id_area_agrupacion: ID_AREA_AGRUPACION.ESCRITURAS_PUBLICAS
              },
              {
                nombre: "fecha",
                valor: new Date(parseInt(fecha_split[0]), parseInt(fecha_split[1]) - 1, parseInt(fecha_split[2])).getTime(),
                id_tipo_formulario: ID_TIPO_FORMULARIO.ESCRITURAS_PUBLICAS,
                id_area_agrupacion: ID_AREA_AGRUPACION.ESCRITURAS_PUBLICAS
              },
              {
                nombre: "contratantes",
                valor: this.contratantes,
                id_tipo_formulario: ID_TIPO_FORMULARIO.ESCRITURAS_PUBLICAS,
                id_area_agrupacion: ID_AREA_AGRUPACION.ESCRITURAS_PUBLICAS
              }
            ]
          }
        }

        return formulario;
      } else {
        return null
      }
    },
    /*
    getEndDate() {
      let endDate = new Date(10800000);
      return endDate.toISOString().slice(0, 10)
    },
    */
    validarFormularioValido() {

      let fecha_split = this.fecha.split("-");
      let fecha_parsed;
      if (!this.notario_no_encontrado && (this.notaria_manual != null || this.notaria_manual.trim().length != 0) && this.escrituraIsBuscada) {
        return (this.repertorio != null && this.repertorio != '' && this.fecha != '' && this.fecha != null && this.notario_select != null);
      }
      if (fecha_split.length < 3) {
        fecha_parsed = -1
      } else {
        //   console.log("fecha_split", fecha_split);
        fecha_parsed = new Date(parseInt(fecha_split[0]), parseInt(fecha_split[1]) - 1, parseInt(fecha_split[2])).getTime()
        console.log("validando fecha 2:", fecha_parsed);
      }

      if (this.notario_no_encontrado && (this.notaria_manual == null || this.notaria_manual.trim().length == 0)) {
        return false;
      }

      if (!this.notario_no_encontrado && this.notario_select == null) {
        return false;
      }
      if (this.radioSabeRepertorio == '1') {
        console.log("caso 1")
        return this.repertorio != null && this.repertorio > 0 && this.repertorio_anho != null && this.repertorio_anho > 0
            && this.contratantes != null
            && this.contratantes.trim().length > 0
      } else {
        console.log("caso 2")
        if (this.radioSabeFecha == '1') {
          console.log("caso 2 1")
          return this.fecha.trim().length > 0
              && this.contratantes != null
              && this.contratantes.trim().length > 0
              //&& fecha_parsed >= this.fecha_minima
              && fecha_parsed <= new Date().getTime();
        } else {
          console.log("caso 2 2")
          return false;
        }
      }
    }
  },
  watch: {
    NoSeRepertorio() {
      if (this.NoSeFecha && this.NoSeRepertorio) {
        this.$emit('MostrarElemento', false)
      } else {
        this.$emit('MostrarElemento', true)
      }
    },
    NoSeFecha() {
      if (this.NoSeFecha && this.NoSeRepertorio) {
        this.$emit('MostrarElemento', false)
      } else {
        this.$emit('MostrarElemento', true)
      }
    },
    getDataFormulario() {
      if (this.validarFormularioValido) {
        this.$emit("setFormulario", this.getDataFormulario)
      } else {
        this.$emit("setFormulario", null)
      }
    },
    notario_no_encontrado(newVal) {
      this.radioSabeRepertorio = null
      this.radioSabeFecha = null
      this.fecha = ""
      this.notaria_manual = ""
      this.contratantes = ""
      this.escrituraIsBuscada = false
      this.repertorio = null
      this.$emit('notarioNoEstaEnLista', this.notario_no_encontrado)
      console.log(newVal)
    },

    camposHabilitados() {

    },

  }
}
</script>

<style scoped>

::v-deep .my-checkbox .v-label {
  font-size: 13px;
}

.v-alert {
  margin-bottom: 0px;
}

.por_arriba {
  position: absolute;
  top: 25px; /* Mueve el div 25 pixeles hacia arriba */
  left: 0;
  z-index: 2; /* Establece un z-index mayor */
}

.por_debajo {
  position: relative;
  z-index: 1;
}
</style>
