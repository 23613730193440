<template>
  <div class="d-flex flex-column flex-md-row mt-3">
    <div class="d-flex flex-column flex-md-row mt-3">
      <indices-nav-estructuras-publicas @itemSeleccionado="itemNav = $event"></indices-nav-estructuras-publicas>
    </div>
    <div class="flex-fill ml-md-3 ">
      <nav-tipo-estructuras-publicas :item-nav="itemNav"></nav-tipo-estructuras-publicas>
    </div>
  </div>
</template>

<script>
import IndicesNavEstructurasPublicas from "@/components/EscriturasPublicas/IndicesNavEstructurasPublicas.vue";
import NavTipoEstructurasPublicas from "@/components/EscriturasPublicas/NavTipoEstructurasPublicas.vue";

export default {
  name: "ConsultasEnLineaViewEscriturasPublicas",
  components: {NavTipoEstructurasPublicas, IndicesNavEstructurasPublicas},

  data(){
    return{
      itemNav: null,
    }
  },

  methods:{

  },
}
</script>

<style scoped>

</style>
