<template>
  <v-card class="pa-5 mt-3">
    <v-form @submit.prevent="handleConsultarEstado()">
      <div
          class="d-flex flex-column flex-sm-row align-stretch align-sm-baseline"
      >
        <v-text-field
            v-model.number="numeroCaratula"
            label="Ingrese número de carátula"
            type="number"
            ref="caratulaInput"
            required
            hide-details
        ></v-text-field>
        <v-btn
            class="ml-0 ml-sm-3 mt-3 mt-sm-0"
            color="primary"
            :loading="cargandoInfo"
            type="submit"
            small
        >Consultar estado
        </v-btn
        >
      </div>

      <v-slide-y-transition mode="out-in">
        <div class="mt-3" key="informacion-caratula" v-if="infoCaratula">
          <v-row>
            <v-col class="pb-0" cols="12">
              <v-alert text outlined border="left" class="text-center" color="info">Para consultar por la existencia de
                documentos electrónicos, debe ingresar el RUT del requirente de la carátula
                <br>
                <v-btn small class="mt-3" color="info"
                       :to="'/consultas/entrega-en-linea/' + infoCaratula.numero_caratula">Ingresar rut del requirente
                </v-btn>
              </v-alert>
            </v-col>
            <v-col cols="12" md="6">

              <!-- card info escencial de carátula-->
              <v-card outlined color="primary" dark v-if="infoCaratula.id_ultimo_estado != 18">
                <v-card-title class="headline pb-2">
                  Carátula {{ infoCaratula.numero_caratula }}
                </v-card-title>
                <v-card-text>
                  <div>
                    <div>
                      <v-icon size="16">mdi-calendar</v-icon>
                      {{
                        estadosCaratulaOrdenados[0].fecha
                            | moment("DD/MM/YYYY HH:mm")
                      }}
                    </div>
                    <div class="font-weight-normal white--text">
                      <strong>{{
                          estadosCaratulaOrdenados[0].estado_caratula
                        }}</strong>
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-alert
                  :type="false"
                  color="error"
                  text
                  outlined
                  border="left"
                  v-if="infoCaratula.id_ultimo_estado == ID_ESTADO_CARATULA.PRESUNTIVA"
              >
                  <v-card-title class="headline pb-2 pt-0">
                    Carátula {{ infoCaratula.numero_caratula }}
                  </v-card-title>
                  <v-card-text class="pb-0">
                    <div>
                      <div>
                        <v-icon color="error" size="16">mdi-calendar</v-icon>
                        {{
                          estadosCaratulaOrdenados[0].fecha
                              | moment("DD/MM/YYYY HH:mm")
                        }}
                      </div>
                      <div class="font-weight-normal ">
                        <strong>{{
                            estadosCaratulaOrdenados[0].estado_caratula
                          }}</strong>
                      </div>

                      <div class="text-center pt-3">
                        <v-btn  small outlined :to="'/consultas/entrega-en-linea/' + infoCaratula.numero_caratula"
                                color="error"><v-icon left small>mdi-magnify</v-icon>Ver motivo del rechazo</v-btn>
                      </div>
                    </div>
                  </v-card-text>
              </v-alert>
              <!-- card de trámites -->
              <v-card
                  v-if="documentosSolicitudCategorizados.length !== 0"
                  outlined
                  class="mt-6"
              >
                <v-card-title class="headline pb-2"> Trámites</v-card-title>
                <v-card-text>
                  <div
                      v-for="(categoria,
                    index) in documentosSolicitudCategorizados"
                      :key="index"
                      class="mb-0"
                  >
                    {{ categoria.tipo_registro }}

                    <div
                        v-for="(tramite, index) in categoria.documentos_solicitud"
                        :key="index"
                        class="font-weight-light"
                    >
                      {{ tramite.numero_copias }} x
                      {{ tramite.tipo_certificado }}
                    </div>
                  </div>
                  <div>
                    <v-list-item class="pl-0" two-line>
                      <v-list-item-content>
                        <v-list-item-title class="subtitle-1">
                          Valor inicial
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ infoCaratula.precio_inicial_pagado | clp }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </v-card-text>
              </v-card>
              <!-- chilexpress -->
              <v-card
                  v-if="infoCaratula.comentario_chilexpress"
                  outlined
                  class="mt-6"
              >
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="headline">
                      Chilexpress
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-uppercase">
                      <v-icon size="20">mdi-email</v-icon>
                      {{ infoCaratula.comentario_chilexpress }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
              <!-- saldo -->
              <v-card
                  v-if="infoCaratula.saldo !== 0"
                  outlined
                  class="mt-6"
              >
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="headline">
                      {{ Math.abs(infoCaratula.saldo) | clp }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-show="infoCaratula.saldo > 0"
                    >
                      <v-icon size="16">mdi-alert</v-icon>
                      Debe pagar este saldo
                    </v-list-item-subtitle
                    >
                    <v-list-item-subtitle v-show="infoCaratula.saldo < 0"
                    >Saldo a favor
                    </v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">

              <v-card outlined>
                <v-card-title class="headline pb-2">Historial de movimientos</v-card-title>
                <v-card-text>
                  <v-timeline
                      align-top
                      dense
                      class="pt-0"
                  >
                    <v-timeline-item
                        class="pb-4 py-0"
                        v-for="(estado, index) in estadosCaratulaOrdenados"
                        :key="index"
                        :color="index === 0 ? 'secondary' : 'primary'"
                        small
                    >
                      <div>
                        <div>
                          <v-icon size="16">mdi-calendar</v-icon>
                          {{ estado.fecha | moment("DD/MM/YYYY HH:mm") }}
                        </div>
                        <div class="font-weight-normal">
                          <strong>{{ estado.estado_caratula }}</strong>
                        </div>
                      </div>
                    </v-timeline-item>
                  </v-timeline>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <div class="mt-3" key="error-caratula" v-else-if="error">
          <v-row>
            <v-col cols="12" md="6">
              <v-card
                  dark
                  outlined
                  class="fill-height"
                  color="warning"
              >
                <v-card-title class="headline">
                  Carátula {{ this.$route.params.ncaratula }}
                </v-card-title>
                <v-card-subtitle>
                  <div>
                    <div class="font-weight-normal white--text">
                      <strong>No disponible</strong>
                    </div>
                  </div>
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-alert
                  class="fill-height mb-0"
                  outlined
                  type="warning"
                  border="left"
              >
                {{ error }}
              </v-alert>
            </v-col>
          </v-row>
        </div>
        <div class="mt-3" key="primera-pantalla" v-else>
          <v-row>
            <v-col cols="12">
              <v-card outlined>
                <v-card-title>
                  <v-icon left>mdi-information</v-icon>
                  Consulta de carátulas
                </v-card-title>
                <v-card-text>
                  En esta sección usted podrá visualizar:
                  <br/>
                  Estado actual, contenido solicitado, historial de movimientos, observaciones y valores de la carátula
                  ingresada.
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>

      </v-slide-y-transition>

      <!--

      <v-slide-y-transition>
        <div v-if="infoCaratula">
            <v-row>
              <v-col cols="12" md="6">

                <v-card outlined color="primary" dark>
                  <v-card-title class="headline pb-2">
                    Carátula {{ infoCaratula.numero_caratula }}
                  </v-card-title>
                  <v-card-text>
                    <div>
                      <div>
                        <v-icon size="16">mdi-calendar</v-icon>
                        {{
                          estadosCaratulaOrdenados[0].fecha
                              | moment("DD/MM/YYYY HH:mm")
                        }}
                      </div>
                      <div class="font-weight-normal white--text">
                        <strong>{{
                            estadosCaratulaOrdenados[0].estado_caratula
                          }}</strong>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
                <v-card
                    v-if="documentosSolicitudCategorizados.length !== 0"
                    outlined
                    class="mt-6"
                >
                  <v-card-title class="headline pb-2"> Trámites</v-card-title>
                  <v-card-text>
                    <div
                        v-for="(categoria,
                    index) in documentosSolicitudCategorizados"
                        :key="index"
                        class="mb-0"
                    >
                      {{ categoria.tipo_registro }}

                      <div
                          v-for="(tramite, index) in categoria.documentos_solicitud"
                          :key="index"
                          class="font-weight-light"
                      >
                        {{ tramite.numero_copias }} x
                        {{ tramite.tipo_certificado }}
                      </div>
                    </div>
                    <div>
                      <v-list-item class="pl-0" two-line>
                        <v-list-item-content>
                          <v-list-item-title class="subtitle-1">
                            Valor inicial
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ infoCaratula.precio_inicial_pagado | clp }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-card-text>
                </v-card>
                <v-card
                    v-if="infoCaratula.comentario_chilexpress"
                    outlined
                    class="mt-6"
                >
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title class="headline">
                        Chilexpress
                      </v-list-item-title>
                      <v-list-item-subtitle class="text-uppercase">
                        <v-icon size="20">mdi-email</v-icon>
                        {{ infoCaratula.comentario_chilexpress }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
                <v-card
                    v-if="infoCaratula.saldo !== 0"
                    outlined
                    class="mt-6"
                >
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title class="headline">
                        {{ Math.abs(infoCaratula.saldo) | clp }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-show="infoCaratula.saldo > 0"
                      >
                        <v-icon size="16">mdi-alert</v-icon>
                        Debe pagar este saldo
                      </v-list-item-subtitle
                      >
                      <v-list-item-subtitle v-show="infoCaratula.saldo < 0"
                      >Saldo a favor
                      </v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">

                <v-card outlined>
                  <v-card-title class="headline pb-2">Historial de movimientos</v-card-title>
                  <v-card-text>
                    <v-timeline
                        align-top
                        dense
                        class="pt-0"
                    >
                      <v-timeline-item
                          class="pb-4 py-0"
                          v-for="(estado, index) in estadosCaratulaOrdenados"
                          :key="index"
                          :color="index === 0 ? 'secondary' : 'primary'"
                          small
                      >
                        <div>
                          <div>
                            <v-icon size="16">mdi-calendar</v-icon>
                            {{ estado.fecha | moment("DD/MM/YYYY HH:mm") }}
                          </div>
                          <div class="font-weight-normal">
                            <strong>{{ estado.estado_caratula }}</strong>
                          </div>
                        </div>
                      </v-timeline-item>
                    </v-timeline>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
        </div>
      </v-slide-y-transition>

      <v-slide-y-transition>
        <div v-if="error">
          <v-row>
            <v-col cols="12" md="6">
              <v-card class="mb-7" color="warning" dark>
                <v-card-title class="headline">
                  Carátula {{ this.$route.params.ncaratula }}
                </v-card-title>
                <v-card-subtitle>
                  <div>
                    <div class="font-weight-normal white--text">
                      <strong>No disponible</strong>
                    </div>
                  </div>
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-alert outlined type="warning" border="left">
                {{ error }}
              </v-alert>
            </v-col>
          </v-row>
        </div>
      </v-slide-y-transition>

      -->

    </v-form>
  </v-card>
</template>

<script>
import caratula, {ID_ESTADO_CARATULA} from "@/model/caratula/caratula.entity";

export default {
  created: function () {
    this.$vuetify.goTo(0);
  },
  mounted: function () {
    this.handleCaratulaObtenida();
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.handleCaratulaObtenida();
  },
  data: function () {
    return {
      cargandoInfo: false,
      infoCaratula: null,
      numeroCaratula: null,
      error: null,
      ID_ESTADO_CARATULA:ID_ESTADO_CARATULA
    };
  },
  methods: {
    handleCaratulaObtenida() {
      if (!this.$vuetify.breakpoint.mobile) {
        this.$refs.caratulaInput.focus();
      }

      //si se tiene el número de carátula por url
      if (this.$route.params.ncaratula) {
        this.numeroCaratula = this.$route.params.ncaratula;
        this.obtenerCaratula();
      }
    },
    handleConsultarEstado() {
      this.$router.replace({
        name: "ConsultasCaratulas",
        params: {ncaratula: this.numeroCaratula},
      });
    },
    obtenerCaratula() {
      this.cargandoInfo = true;
      this.error = null;
      this.infoCaratula = null;
      const vm = this;

      caratula
          .getEstadoCaratula(this.numeroCaratula)
          .then((response) => {
            vm.infoCaratula = response;
          })
          .catch((error) => {
            vm.error = error.mensaje ? error.mensaje : error;
            console.error(error);
          })
          .finally(() => {
            vm.cargandoInfo = false;
          });
    },
  },
  computed: {
    estadosCaratulaOrdenados: function () {
      let estadosCaratulaOrdenados;
      estadosCaratulaOrdenados = this.infoCaratula.estados_caratula;
      return estadosCaratulaOrdenados.sort((a, b) =>
          a.fecha > b.fecha ? -1 : b.fecha > a.fecha ? 1 : 0
      );
    },
    documentosSolicitudCategorizados: function () {
      if (this.infoCaratula.documentos_solicitud.lenght === 0) {
        return null;
      }

      var id_categoria = [];
      this.infoCaratula.documentos_solicitud.map((d) => {
        if (!id_categoria.includes(d.id_tipo_registro_FK)) {
          id_categoria.push(d.id_tipo_registro_FK);
        }
      });

      var docCategorizados = [];
      id_categoria.map((i) => {
        var obj = {};

        obj.documentos_solicitud = this.infoCaratula.documentos_solicitud.filter(
            (f) => {
              return f.id_tipo_registro_FK === i;
            }
        );

        obj.tipo_registro = obj.documentos_solicitud[0].tipo_registro;
        docCategorizados.push(obj);
      });

      return docCategorizados;
    },
  },
};
</script>

<style scoped>
.v-card__title {
  word-break: normal;
}
</style>