<template>
  <div>
    <v-row v-if="itemNav != null">
      <v-col cols="12" class="text-center pt-0 pl-md-5" v-if="itemNav != null">
        <v-tabs
            centered
            center-active
            show-arrows
        >
          <v-tab href="#nav-1">Escrituras Públicas</v-tab>
          <v-tab-item
              value="nav-1"
          >
            <v-card-text>
              <formulario-escritura-publica-copia
                  :campos-habilitados="true"
                  @MostrarElemento="mostrarModal"
                  @setFormulario="dataFormulario = $event"
                  @e-cambiar-valor-certificado="cambiarValorCertificado($event)"
              ></formulario-escritura-publica-copia>
            </v-card-text>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
    <modal-confirma-escritura-publica
        :ver-dialog="modalConfirmaEscrituraPublica"
        @ocultarDialog="modalConfirmaEscrituraPublica = false"
        @confirmarEscrituraPublica="confirmarEscrituraPublica">
    </modal-confirma-escritura-publica>
    <modal-documento-agregado
        :verDialog="modalAgregar.verModalDocumentoAgregado"
        :documentosAgregados="modalAgregar.lista_agregados"
        :documentosRepetidos="modalAgregar.documentos_repetidos"
        :documentosEnLimite="modalAgregar.limite_carro"
        @ocultarDialog="modalAgregar.verModalDocumentoAgregado = false">
    </modal-documento-agregado>
  </div>
</template>

<script>
import FormularioEscrituraPublicaCopia from "@/components/EscriturasPublicas/FormularioEscrituraPublicaCopia.vue";
import ModalConfirmaEscrituraPublica from "@/components/Tramites/ModalConfirmaEscrituraPublica.vue";
import {ID_TIPO_FORMULARIO} from "@/model/formularios/formulario.entity";
import registros, {ID_AREA_AGRUPACION, ID_TIPO_CERTIFICADO, ID_TIPO_REGISTRO} from "@/model/registros/registros.entity";
import carro from "@/model/carro/carro.entity";
import ModalDocumentoAgregado from "@/components/ModalDocumentoAgregado.vue";

export default {
  name: "NavTipoEstructurasPublicas",
  components: {ModalDocumentoAgregado, ModalConfirmaEscrituraPublica, FormularioEscrituraPublicaCopia},

  props: {
    itemNav: {default: null}
  },

  data() {
    return {
      modalConfirmaEscrituraPublica: false,
      dataFormulario: null,
      modalAgregar: {
        documentos_repetidos: [],
        limite_carro: [],
        lista_agregados: [],
        verModalDocumentoAgregado: false
      },
      certificado:null,
      url_tipo_certificado:'copia-escritura',
      url_tipo_registro : 'escrituras-publicas',
      sugerencias: {
        foja: 0,
        fojaBuscada: 0,
        numero_carillas: 0,
        precio_carillas: 0,
        nombre_comunidad: "",
      },
      cerDomVigente: null,
    }
  },
  created() {
    this.llamarCertificadoEscrituras()
  },
  methods: {
    llamarCertificadoEscrituras(){
      registros.getCertificadoTramitesEnLinea(this.url_tipo_registro, this.url_tipo_certificado).then(response => {
        this.certificado = response.certificado
        this.sugerencias = response.lista_certificados


        /**
         * Si es un item que lleva documentos cargados desde SCBR, carga en memoria los valores de los certificados que se rescatarán
         * Se evalúa esta condición para evitar consultas adicionales
         * La búsqueda de los documentos que se tienen que agregar se hará al momento de validar el índice digitado por el cliente
         */
        if (this.isItemConScbr) {

          registros.getFromTiposRegistrosTiposCertificados(ID_TIPO_REGISTRO.REGISTRO_DE_PROPIEDAD, ID_TIPO_CERTIFICADO.COPIA_AUTORIZADA_HIPOTECA).then(r => {
            this.certificadoHipoteca = r
          })
          registros.getFromTiposRegistrosTiposCertificados(ID_TIPO_REGISTRO.REGISTRO_DE_PROPIEDAD, ID_TIPO_CERTIFICADO.COPIA_AUTORIZADA_PROHIBICION).then(r => {
            this.certificadoProhibiciones = r
          })

          registros.getFromTiposRegistrosTiposCertificados(ID_TIPO_REGISTRO.ALZAMIENTO_SERVIU_PLAZO_VENCIDO, ID_TIPO_CERTIFICADO.ALZAMIENTO_PLAZO_VENCIDO).then(r => {
            this.alzamientoServiu = r;
          })
        }

        switch (this.certificado.id_tipo_registro_FK) {
          case registros.ID_TIPO_REGISTRO.ALZAMIENTO_Y_CANCELACION:
            if (!carro.verificarTipoAlzamiento(this.certificado)) {
              //incompatibilidad de carros... volver a la pagina anterior
              this.verModalIncompatibilidadAlzamiento = true;
            }

            if (carro.obtenerAlzamiento()) {
              this.dataFormulario = carro.obtenerAlzamiento();
              this.formularioAlzamientoPrecargado = true;
            } else {
              //this.mostrarDocumentosTentativos = false;
              this.formularioAlzamientoPrecargado = false;
            }

            break;

          case registros.ID_TIPO_REGISTRO.ESCRITURAS_PUBLICAS:
            this.dataFormulario = carro.getDatosAdicionalesCertificado(ID_TIPO_REGISTRO.ESCRITURAS_PUBLICAS, ID_AREA_AGRUPACION.ESCRITURAS_PUBLICAS);

            if (this.dataFormulario) {
              this.formularioEscrituraPublicaPrecargado = true;

            } else {
              this.formularioEscrituraPublicaPrecargado = false;
            }
            break;
          default:
            //this.mostrarDocumentosTentativos = true;

        }
      }).catch(() => {
        this.certificado = {
          data: "Certificado no encontrado"
        }
      })
    },
    cambiarValorCertificado(nuevoValorCertificado) {
      console.log("asdasdasdsd", nuevoValorCertificado)
      this.sugerencias.precio = nuevoValorCertificado
    },

    mostrarModal() {
      this.modalConfirmaEscrituraPublica = true
    },

    confirmarEscrituraPublica() {
      this.modalConfirmaEscrituraPublica = false;
      this.agregarAlCarro();
    },
    cerrarModalSerie() {
      this.dialogSerie = false;
    },

    agregarAlCarro() {

      let sug = JSON.parse(JSON.stringify(this.sugerencias));
      let items_alz = [];

      if (this.isItemConScbr) {

        let hipotecas = JSON.parse(JSON.stringify(this.$refs.panelItemsTitulo.getInformacionHipotecas()));
        let prohibiciones = JSON.parse(JSON.stringify(this.$refs.panelItemsTitulo.getInformacionProhibiciones()));
        let alzamientos = JSON.parse(JSON.stringify(this.$refs.panelItemsTitulo.getInformacionAlzamientos()));

        hipotecas.map(h => {

          if (h.numero_copias > 0) {
            let copiaCertificadoHipoteca = {...this.certificadoHipoteca}
            copiaCertificadoHipoteca.precio = h.precio_total
            sug.push({
              ...copiaCertificadoHipoteca,
              indice: h.foja_hipoteca + h.acompanamiento_foja + "-" + h.numero_hipoteca + h.acompanamiento + "-" + h.anho_hipoteca,
              numero_copias: h.numero_copias
            })
          }
        })

        prohibiciones.map(p => {
          if (p.numero_copias > 0) {

            let copiaProhibiciones = {...this.certificadoProhibiciones}

            copiaProhibiciones.precio = p.precio_total
            sug.push({
              ...copiaProhibiciones,
              indice: p.foja_prohibicion + p.acompanamiento_foja + "-" + p.numero_prohibicion + p.acompanamiento + "-" + p.anho_prohibicion,
              numero_copias: p.numero_copias
            })
          }
        })

        alzamientos.map(a => {
          if (a.numero_copias > 0) {
            let copiaAlzamientoServiu = {...this.alzamientoServiu}
            copiaAlzamientoServiu.precio = a.precio_total
            items_alz.push({
              ...copiaAlzamientoServiu,
              indice: "No aplica",
              numero_copias: a.numero_copias
            })
            this.$set(this.dataFormulario, "id_tipo_formulario", ID_TIPO_FORMULARIO.FNA)
            this.$set(this.dataFormulario, "datos_adicionales", [{
              nombre: "titulo",
              valor: this.dataFormulario.foja + "-" + this.dataFormulario.numero + "-" + this.dataFormulario.anho,
              id_tipo_formulario: ID_TIPO_FORMULARIO.FNA,
              id_area_agrupacion: ID_AREA_AGRUPACION.ALZAMIENTO_PROHIBICION_PLAZO_VENCIDO
            }, {
              nombre: "prohibicion",
              valor: a.indice,
              id_tipo_formulario: ID_TIPO_FORMULARIO.FNA,
              id_area_agrupacion: ID_AREA_AGRUPACION.ALZAMIENTO_PROHIBICION_PLAZO_VENCIDO
            }

            ])
          }
        })

      }
      let data2 = null;
      if (items_alz.length > 0) {
        data2 = {
          ...this.dataFormulario,
          id_tipo_formulario: ID_TIPO_FORMULARIO.ALZAMIENTO_PLAZO_VENCIDO
        }
        this.dataFormulario.datos_adicionales = null;
      }
      this.documentosAgregados = true;

      //aqui se cambia el tipo de certificado si es que hay un comunero.
      for (let i = 0; i < sug.length; i++) {
        //se agrega campo notas para saber el nombre del comunero
        sug[i].notas = ""
        if (sug[i].id_tipo_certificado === registros.ID_TIPO_CERTIFICADO.COPIA_CON_VIGENCIA_O_DOMINIO_VIGENTE && this.mostrarMensajeComunidad) {
          sug[i] = {...this.cerDomVigente}
          sug[i].numero_copias = 1
          sug[i].vigencia = -1
          sug[i].notas = "Nombre del Comunero : " + this.nombreComunero
        }
      }
      console.log("sug", sug, "dataformulario", this.dataFormulario, this.precio_adicional_carillas)
      carro.agregarAlCarro(sug, this.dataFormulario, this.precio_adicional_carillas).then(response => {
        if (items_alz.length > 0) {
          carro.agregarAlCarro(items_alz, data2, this.precio_adicional_carillas).then(r2 => {
            this.modalAgregar.verModalDocumentoAgregado = false
            this.modalAgregar.documentos_repetidos = [
              ...response.documentos_repetidos,
              ...r2.documentos_repetidos
            ];
            this.modalAgregar.limite_carro = [
              ...response.limite_carro,
              ...r2.limite_carro
            ];
            this.modalAgregar.lista_agregados = [
              ...response.lista_agregados,
              ...r2.lista_agregados]
            this.modalAgregar.verModalDocumentoAgregado = true;
          })
        } else {
          this.verModalAgregarAlzamiento = false;
          this.modalAgregar.documentos_repetidos = response.documentos_repetidos;
          this.modalAgregar.limite_carro = response.limite_carro
          this.modalAgregar.lista_agregados = response.lista_agregados;
          this.modalAgregar.verModalDocumentoAgregado = true;
        }
      })

    },
  },
}
</script>
<style scoped>

</style>
