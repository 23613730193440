<template>
  <v-dialog max-width="500" v-model="ver">
    <v-card>
      <h3 class="text-center py-4">{{mensajeTitulo}}</h3>
      <v-card-text class="text-center">{{mensaje}}</v-card-text>
      <v-card-text class="text-center">
        <v-btn @click="hideDialog" color="info">volver</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "ModalMensajeSimpleReutilizable",
  props: {
    mensajeTitulo:{default:'mensaje titulo'},
    mensaje:{default:'mensaje'},
    verDialog: {default: false},
    time: {default: null},
    tipo: {
      default: "info"
    }
  },
  data() {
    return {
      ver: false
    }
  },

  watch: {
    verDialog() {
      this.ver = this.verDialog;

      if (this.time != null) {
        if (!isNaN(this.time)) {
          let autoclose = parseInt(this.time) * 1000;
          setTimeout(() => this.hideDialog(), autoclose)
        }

      }
    },

    ver(newVal) {
      if (!newVal) {
        this.$emit("ocultarDialog", false);
      }
    }
  },

  methods: {
    hideDialog() {
      this.$emit("ocultarDialog", false);
    }
  }
}
</script>

<style scoped>

</style>