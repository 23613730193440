<template>
  <div>
    <v-card
        class="mx-auto"
        width="256"
        tile
    >
      <div>
        <v-list>
          <v-list-item>
            <v-list-item-title class="title">
              Consultas en Línea
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list nav dense>
          <v-list-item-group
              v-model="selectedItem"
              color="primary"
              mandatory
          >
            <v-list-item
                v-for="(item, i) in items"
                :key="i"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "IndicesNavEstructurasPublicas",

  data() {
    return {
      selectedItem: 0,
      items: [
        {
          text: 'Escrituras públicas',
          icon: 'mdi-file',
          menus: [0,1],
        },
      ],
    }
  },

  created() {
    this.$emit("itemSeleccionado", this.items[this.selectedItem])
  },

  watch: {
    selectedItem() {
      this.$emit("itemSeleccionado", this.items[this.selectedItem])
    }
  },

}
</script>

<style scoped>

</style>
