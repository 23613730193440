import { render, staticRenderFns } from "./PanelBuscarPorTitularEscrituraPublicas.vue?vue&type=template&id=7ea1bf94&scoped=true&"
import script from "./PanelBuscarPorTitularEscrituraPublicas.vue?vue&type=script&lang=js&"
export * from "./PanelBuscarPorTitularEscrituraPublicas.vue?vue&type=script&lang=js&"
import style0 from "./PanelBuscarPorTitularEscrituraPublicas.vue?vue&type=style&index=0&id=7ea1bf94&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ea1bf94",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAlert,VBtn,VCol,VFadeTransition,VIcon,VRow,VTextField})
