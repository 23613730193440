<template>
  <div class="pt-3">
    <form @submit.prevent="titularEscritura ? buscarTitularesEscritura() : buscarTitulares()">
      <v-row class="px-3">
        <v-col cols="12" md="6" sm="7" class="py-sm-1 pt-0 pb-3 mx-0 pl-0 px-0-small">
          <v-text-field
              dense
              label="Nombre y apellido(s) del propietario"
              placeholder=" "
              outlined
              :autofocus="!$vuetify.breakpoint.mobile"
              v-model="titular"
              hide-details="auto"
              required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3" sm="5" class="py-sm-1 pb-3 px-0-small">
          <v-text-field
              dense
              label="Año de la inscripción"
              placeholder=" "
              outlined
              v-model="anho"
              type="number"
              hide-details="auto"
              required
          ></v-text-field>
        </v-col>

        <v-col cols="12"  md="3" class="py-0 pt-0 pt-md-0  pr-0 px-0-small-btn">
          <div class="text-center pt-0">
            <v-btn
                type="submit"
                height="38"
                class="px-10 mt-1"
                block
                color="primary"
                :loading="cargandoTitulares"
                :disabled="cargandoTitulares">
              <v-icon small left>mdi-magnify</v-icon>
              <span>Buscar</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </form>

    <v-alert
        v-if="primeraBusqueda"
        type="info"
        outlined
        border="left"
        :icon="false"
        class="text-center mt-5">
      <p class="mb-0 font-md info--text">
        Aquí podrá encontrar los datos de reperorio, notaría y fecha en la cual fue firmada una escritura asociada a uno o más títulos del Registro de Propiedad.
      </p>

    </v-alert>
    <v-fade-transition>
        <panel-titulares-encontrados
                v-if="!primeraBusqueda && titularEscritura === false"
                :lista-titulos="listaTitulos"
                @recargarBusqueda="buscarTitulares"
                @seleccionarItem="seleccionarItem"
        />
        <busqueda-titulares-propiedad-escritura-tabla
                v-if="!primeraBusqueda && titularEscritura === true"
                :lista-titulos="listaTitulos"
                @recargarBusqueda="buscarTitularesEscritura"
                @seleccionarItem="seleccionarItemEscritura"
                @mostrarModalProblemaEscritura="mostrarModalProblemaEscritura($event)"
        />
    </v-fade-transition>

  </div>
</template>

<style scoped>
@media (max-width: 960px) {
  .px-0-small{
    padding-left: 10px;
    padding-right: 0px;
  }
  .px-0-small-btn{
    padding-left: 0px;
    padding-right: 0px;
  }

}
@media (max-width: 600px) {
  .px-0-small{
    padding-left: 0px;
    padding-right: 0px;
  }

  .px-0-small-btn{
    padding-left: 0px;
    padding-right: 0px;
  }
}
</style>


<script>
import tituloEntity from "@/model/titulo/titulo.entity";
import PanelTitularesEncontrados from "@/views/index/indices/PanelTitularesEncontrados";
import BusquedaTitularesPropiedadEscrituraTabla
    from "@/components/BusquedaTitularesPropiedad/BusquedaTitularesPropiedadEscrituraTabla.vue";

export default {
  name: "PanelBuscarPorTitular",
  components: { PanelTitularesEncontrados, BusquedaTitularesPropiedadEscrituraTabla},
  props: {
    itemNav: {
      type: Object,
      default: () => {
      }
    },
    titularEscritura: {
        type: Boolean,
        required: true,
    }
  },
  data() {
    return {
      titular: "",
      buscando: false,
      anho: "",
      verModalTitularesEncontrados: false,
      listaTitulos: [],
      cargandoTitulares: false,
      primeraBusqueda: true,
      verModalAgregarDocumento: false,
      lista_registros: [],
      itemSeleccionado: null
    }
  },

  methods: {
    seleccionarItem(item, registros) {
      this.$emit("seleccionarItem", item, registros)
      console.log("se va a mostrar el nuevo modal")
      this.lista_registros = registros;
      this.verModalAgregarDocumento = true;
      this.itemSeleccionado = item;
      console.log("vienen los registros?", registros)
      //  this.$emit("seleccionarItem", item, registros)
    },
    buscarTitulares() {
      this.cargandoTitulares = true;
      console.log("buscando titulares")
      tituloEntity.getConsultaTitulares(this.titular, this.anho).then(res => {
        this.primeraBusqueda = false;
        this.cargandoTitulares = false;
        this.listaTitulos = res.obj;
        this.verModalTitularesEncontrados = true;
      })
    },
      seleccionarItemEscritura(item, registros){
          this.$emit("seleccionarItem", item, registros);
      },
      buscarTitularesEscritura(){
          this.cargandoTitulares = true;
          console.log("buscando titulares escrituras")
          tituloEntity.getConsultaTitularesEscrituras(this.titular, this.anho).then(res => {
              this.primeraBusqueda = false;
              this.cargandoTitulares = false;
              this.listaTitulos = res.obj;
              this.verModalTitularesEncontrados = true;
          })
      },
      mostrarModalProblemaEscritura(data){
          this.$emit("mostrarModalProblemaEscritura",data);
      }
  }
}
</script>

